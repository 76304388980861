import LazyLoad from 'react-lazyload'
import React, { useEffect, useState } from 'react'
import compact from 'lodash/compact'
import sum from 'lodash/sum'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { Badge, Button, CustomInput, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

const mediumZoom = hasWindow ? require('medium-zoom') : undefined

import ImageZoom from '../components/common/ImageZoom'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { MedicalImagesForCondition_medical_images_predictions_normalizeds } from '../hasura/graphQlQueries/types/MedicalImagesForCondition'
import { QueryName } from '../hasura/queryNames'
import { consultationsSelector, ConsultationsState } from '../hasura/slices/consultations'
import { medicalImagesForConditionAction, medicalImagesSelector, MedicalImagesState } from '../hasura/slices/medical-images'
import { riskForSeverity } from '../lib/aiHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'
import { emojiFor, hasWindow, searchQueryParams, speciesFor, vetFirstName } from '../lib/helpers'

// @ts-ignore
import aiConfig from '../../aiConfig.json'

const isNegative = (p: MedicalImagesForCondition_medical_images_predictions_normalizeds) =>
  Boolean(p.grade === 1 || p.display_name?.includes('NOT ') || p.issue)

export default function Condition() {
  const dispatch = useDispatch()

  const id = parseInt(searchQueryParams('id') || '', 10)
  const species = searchQueryParams('species') || 'dog'

  const zoom = React.useRef(hasWindow ? mediumZoom.default({ margin: 48 }) : undefined)

  const [filterVetId, setFilterVetId] = useState<string | undefined>(searchQueryParams('v'))
  const [expandedViews, setExpandedViews] = useState<string[]>([])
  const [onlyDisplayDisagreements, setOnlyDisplayDisagreements] = useState(false)
  const [displayPermutations, setDisplayPermutations] = useState(false)

  const { accessToken, vetsExpanded }: UsersState = useSelector(usersSelector)
  const { medicalImagesForCondition, isQuerying }: MedicalImagesState = useSelector(medicalImagesSelector)
  const { presignedCaseImageUrls, conditions }: ConsultationsState = useSelector(consultationsSelector)

  const images = medicalImagesForCondition?.filter((m) =>
    m.predictions_normalizeds.some((p) => p.condition?.id === id && !p.display_name?.includes('NOT '))
  )

  const views = Object.keys(groupBy(images, 'view.display_name')).sort()
  const condition = conditions.find((c) => c.id === id)
  const risk = condition?.severity ? riskForSeverity(condition.severity) : undefined
  const vetIds = uniq(flatten(images?.map((i) => i.predictions_normalizeds.map((p) => p.user?.id))))

  useEffect(() => {
    if (medicalImagesForCondition?.length && onlyDisplayDisagreements) setExpandedViews(views)
  }, [medicalImagesForCondition, onlyDisplayDisagreements])

  useEffect(() => {
    if (!accessToken || !id) return

    setOnlyDisplayDisagreements(searchQueryParams('f') === 'disagreements')
    dispatch(medicalImagesForConditionAction(accessToken, id, species))
  }, [accessToken])

  const vetLabelCounts = sortBy(
    compact(
      vetIds.map((vetId) => {
        const labelCount =
          images?.filter((m) =>
            m.predictions_normalizeds.some(
              (p) =>
                p.condition?.id === id &&
                !p.display_name?.includes('NOT ') &&
                !p.issue &&
                ![1, 2].includes(p.grade || 0) &&
                p.user?.id === vetId
            )
          ).length || 0

        const vet = vetsExpanded.find((v) => v.id === vetId)
        if (!vet || labelCount < 25) return null

        return { name: vetFirstName(vet.display_name), id: vet.id, labelCount }
      })
    ),
    'labelCount'
  ).reverse()

  return (
    <Layout>
      <MainBox defaultPadding>
        <p className="mb-0 text--primary3 ls-sm bold text-uppercase text-m">{condition?.category?.display_name}</p>

        <h1 className="bold mb-0 text--gray8">{condition?.display_name}</h1>

        <div className="d-flex align-items-center">
          {risk && <Badge className={`mr-4 transition-m text-m min-width-150px ${risk.toLowerCase()}-risk`}>{risk} Risk</Badge>}

          <p className="text-xl mb-0">{emojiFor(speciesFor(species))}</p>
        </div>

        {isQuerying[QueryName.MedicalImagesForCondition] ? (
          <Spinner color="primary" />
        ) : (
          <div className="mt-3">
            <div className="d-flex gap-100px align-items-start">
              <div className="text-m">
                <p className="bold m-0">Filter doctor</p>

                {vetLabelCounts.map(({ name, labelCount, id }) => (
                  <div
                    key={id}
                    style={{ opacity: !filterVetId || id === filterVetId ? 1 : 0.5 }}
                    onClick={() => setFilterVetId(id === filterVetId ? undefined : id)}
                    className="d-flex align-items-center pointer"
                  >
                    <p className="min-width-70px m-0">{name}:</p>

                    <p className="m-0 pl-2">{labelCount} positive labels</p>
                  </div>
                ))}

                {vetLabelCounts.length > 0 && (
                  <p className="m-0">{sum(vetLabelCounts.map((v) => v.labelCount))} combined positive labels</p>
                )}
              </div>

              <div>
                <p className="bold m-0">Other settings</p>

                <div className="d-flex align-items-end mb-1">
                  <CustomInput
                    id="disagreements"
                    checked={onlyDisplayDisagreements}
                    className="m-0 custom-switch-light"
                    role="button"
                    type="switch"
                    onChange={() => setOnlyDisplayDisagreements(!onlyDisplayDisagreements)}
                  />

                  <p className="text-m mb-0">
                    {onlyDisplayDisagreements ? 'Display only disagreements' : 'Display agreements and disagreements'}
                  </p>
                </div>

                <div className="d-flex align-items-end">
                  <CustomInput
                    id="permutations"
                    checked={displayPermutations}
                    className="m-0 custom-switch-light"
                    role="button"
                    type="switch"
                    onChange={() => setDisplayPermutations(!displayPermutations)}
                  />

                  <p className="text-m mb-0">{displayPermutations ? 'Display permutations' : 'Do not display permutations'}</p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              {views.map((view) => {
                const filteredForView = images?.filter(
                  (i) =>
                    i.view?.display_name === view &&
                    (!filterVetId || i.predictions_normalizeds.some((p) => p.user?.id === filterVetId))
                )
                if (!filteredForView?.length) return null

                const isExpanded = expandedViews.includes(view)

                return (
                  <div key={view} className="my-2">
                    <div className="mb-2 d-flex align-items-center">
                      <Button
                        color="secondary"
                        size="sm"
                        outline={isExpanded}
                        className="mr-3"
                        onClick={() =>
                          setExpandedViews(isExpanded ? expandedViews.filter((e) => e !== view) : expandedViews.concat(view))
                        }
                      >
                        {isExpanded ? 'Hide' : 'Expand'}
                      </Button>

                      <div className="d-flex">
                        <p className="text-m min-width-125px m-0">{view}:</p>

                        <p className="text-m m-0">{filteredForView?.length} labels</p>
                      </div>
                    </div>

                    {isExpanded && (
                      <div style={{ gap: '30px' }} className="d-flex flex-wrap mb-5">
                        {orderBy(filteredForView, ['id'], ['asc']).map((i, idx) => {
                          const src = presignedCaseImageUrls.find((p) => i.aws_s3_url && p.includes(i.aws_s3_url))
                          if (!src) return null

                          const predictions = i.predictions_normalizeds.filter((p) => p.condition?.id === id)
                          const disagreementExists = predictions.some(isNegative) && predictions.some((p) => !isNegative(p))
                          const consultPredictionVetId = i.case?.consultations.find((c) =>
                            c.predictions.some((p) => p.condition?.id === id)
                          )?.receiving_vet?.id
                          if (!disagreementExists && onlyDisplayDisagreements) return null

                          return (
                            <div className="border rounded py-3 px-4 min-width-250px" key={idx}>
                              <p className="mb-0 text-xxs w-100 text-right">{i.id}</p>

                              <LazyLoad height={250} once>
                                <ImageZoom px={250} src={src} zoom={zoom.current} />
                              </LazyLoad>

                              {i.medical_image_permutations.length > 0 && displayPermutations && (
                                <div className="mt-4 d-flex flex-wrap gap-15px">
                                  {i.medical_image_permutations.map((p, idx) => {
                                    const src = presignedCaseImageUrls.find((p2) => p2.includes(p.aws_s3_url))
                                    if (!src) return null

                                    return (
                                      <LazyLoad
                                        className="position-relative width-fit-content border border--gray6 border-width-2px"
                                        key={idx}
                                        height={100}
                                        once
                                      >
                                        <div
                                          style={{ top: '-14px', height: '12px', lineHeight: '12px', left: '-2px' }}
                                          className="position-absolute bg--gray6 text-white text-xxxs semibold px-2"
                                        >
                                          {p.label}
                                        </div>

                                        <ImageZoom src={src} zoom={zoom.current} px={100} />
                                      </LazyLoad>
                                    )
                                  })}
                                </div>
                              )}

                              <div className="mt-2 text-xs">
                                {predictions.map((p, idx) => {
                                  const vet = vetsExpanded.find((v) => v.id === p.user?.id)
                                  if (!vet) return null

                                  return (
                                    <p key={idx} className={`mb-0 text-m ${isNegative(p) ? 'text--danger' : 'text--gray8'}`}>
                                      {vetFirstName(vet.display_name)}
                                      <span className="pl-2 semibold">
                                        {p.grade ? `${p.grade}/5` : p.issue || (p.display_name?.includes('NOT ') ? 'NO' : 'YES')}
                                      </span>
                                      {p.key_values_json && (
                                        <span className="pl-2">
                                          {Object.values(p.key_values_json)
                                            // @ts-ignore
                                            .map((a: string[]) => a.join('/'))
                                            .join(' - ')}
                                        </span>
                                      )}
                                    </p>
                                  )
                                })}

                                {consultPredictionVetId && (
                                  <p key={idx} className={`mb-0 text-m text--gray8`}>
                                    {vetFirstName(vetsExpanded.find((v) => v.id === consultPredictionVetId)?.display_name)}
                                    <span className="pl-2 semibold">Consult</span>
                                  </p>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </MainBox>
    </Layout>
  )
}
